// Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
// You may obtain a copy of the licence at:
// https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

// From https://catppuccin.com/palette, latte theme
export const COLORS = {
  mauve: '#8839ef',
  red: '#d20f39',
  yellow: '#df8e1d',
  green: '#40a02b',
  blue: '#1e66f5',
}

export type Color = keyof typeof COLORS
