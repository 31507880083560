<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import { ref } from 'vue'

const INATOR = 'inator'

const inator = defineModel('inator', { default: false })

const currentLetter = ref(0)
useEventListener(document, 'keydown', (event: KeyboardEvent) => {
  if (event.key === INATOR[currentLetter.value]) {
    currentLetter.value = (currentLetter.value + 1) % INATOR.length
    if (currentLetter.value === 0) {
      inator.value = !inator.value
    }
  } else {
    currentLetter.value = 0
  }
})
</script>

<template>
  <h1 class="title">{{ inator ? 'discord persinator' : 'discordsona' }}</h1>
</template>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: normal;
}
</style>
