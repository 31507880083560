<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import { ChartData, ChartOptions } from 'chart.js'
import { computed } from 'vue'
import { Pie } from 'vue-chartjs'
import { Color, COLORS } from '../colors'

export interface Tally {
  label: string
  color: Color
  tally: number
}

const options: ChartOptions<'pie'> = {
  responsive: true,
  plugins: {
    tooltip: {
      mode: 'dataset',
    },
  },
}

const props = defineProps<{
  tallies: Tally[]
}>()

const data = computed<ChartData<'pie'>>(() => {
  return {
    labels: props.tallies.map(t => t.label),
    datasets: [{
      data: props.tallies.map(t => t.tally),
      backgroundColor: props.tallies.map(t => COLORS[t.color]),
    }],
  }
})
</script>

<template>
  <Pie :data="data" :options="options" />
</template>
