// Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
// You may obtain a copy of the licence at:
// https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

import { UploadRawFile } from 'element-plus'
export type {
  AgePredictions,
  GenderPredictions,
  LoadOptions,
  PredictionsData,
} from './loader.worker'
import byteSize from 'byte-size'
import * as Comlink from 'comlink'
import { ElNotification } from 'element-plus'
import type { Api, LoadOptions, PredictionsData } from './loader.worker'

const loadingWorker = Comlink.wrap<Api>(
  new Worker(new URL('./loader.worker', import.meta.url), { type: 'module' }),
)

export async function load(
  file: UploadRawFile,
  onProgress: (progress: number) => void,
  options: LoadOptions,
): Promise<PredictionsData> {
  let size = byteSize(file.size, { units: 'iec' })

  let start = performance.now()

  let { port1: progressListener, port2: progressEmitter } = new MessageChannel()
  progressListener.onmessage = (event: MessageEvent<number>) => {
    onProgress(event.data)
  }

  try {
    let result = await loadingWorker.load(
      file,
      Comlink.transfer(progressEmitter, [progressEmitter]),
      options,
    )

    let duration = ((performance.now() - start) / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    console.log({ size, duration })

    ElNotification({
      type: 'info',
      title: 'Loaded data',
      message: `Loaded ${result.events} event(s) (${size}) in ${duration}s`,
      position: 'bottom-right',
    })
    return result
  } catch (ex) {
    ElNotification({
      type: 'error',
      title: 'An error has occurred',
      message: `${ex}`,
      position: 'bottom-right',
    })

    throw ex
  } finally {
    progressEmitter.close()
    progressListener.close()
  }
}
