// Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
// You may obtain a copy of the licence at:
// https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
// @ts-ignore
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './style.scss'
import { init as sentryInit, registerSpanErrorInstrumentation } from '@sentry/vue'

import App from './App.vue'

ChartJS.defaults.backgroundColor = 'black'
ChartJS.defaults.borderColor = 'white'
ChartJS.defaults.font.family = 'Andika, sans-serif'

ChartJS.register(
  ArcElement,
  CategoryScale,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  zoomPlugin,
)

let app = createApp(App)

registerSpanErrorInstrumentation()
sentryInit({
  app,
  enabled: import.meta.env.PROD,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracesSampleRate: 1.0,
})
app.mount('#app')
