<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import { computed } from 'vue'
import type { PredictionsData } from '../loader'
import { Dataset } from './DvProbabilitiesChart.vue'
import { Tally } from './DvTalliesChart.vue'

const props = defineProps<{
  predictions: PredictionsData
  inator: boolean
}>()

const age = computed<(Tally & Dataset)[]>(() => {
  return [
    {
      label: '13-17',
      color: 'red',
      tally: props.predictions.age.tallyY13Y17,
      probabilities: props.predictions.age.probY13Y17,
    },
    {
      label: '18-24',
      color: 'yellow',
      tally: props.predictions.age.tallyY18Y24,
      probabilities: props.predictions.age.probY18Y24,
    },
    {
      label: '25-34',
      color: 'green',
      tally: props.predictions.age.tallyY25Y34,
      probabilities: props.predictions.age.probY25Y34,
    },
    {
      label: '35+',
      color: 'blue',
      tally: props.predictions.age.tallyY35Over,
      probabilities: props.predictions.age.probY35Over,
    },
  ]
})

const gender = computed<(Tally & Dataset)[] | undefined>(() => {
  if (props.predictions === undefined) {
    return undefined
  }

  return [
    {
      label: 'p(Female)',
      color: 'red',
      tally: props.predictions.gender.tallyFemale,
      probabilities: props.predictions.gender.probFemale,
    },
    {
      label: 'p(Male)',
      color: 'blue',
      tally: props.predictions.gender.tallyMale,
      probabilities: props.predictions.gender.probMale,
    },
    {
      label: 'p(NonBinaryGenderExpansive)',
      color: 'mauve',
      tally: props.predictions.gender.tallyNonBinaryGenderExpansive,
      probabilities: props.predictions.gender.probNonBinaryGenderExpansive,
    },
  ]
})
</script>

<template>
  <div class="grid">
    <h2 class="title age-title">{{ inator ? 'Ageinator' : 'Age' }}</h2>
    <div class="tallies chart">
      <DvTalliesChart :tallies="age" />
    </div>
    <div class="probabilities chart">
      <DvProbabilitiesChart :times="props.predictions.age.dayPt" :datasets="age" />
    </div>

    <h2 class="title gender-title">{{ inator ? 'Genderinator' : 'Gender' }}</h2>
    <div class="tallies chart">
      <DvTalliesChart :tallies="gender" />
    </div>
    <div class="probabilities chart">
      <DvProbabilitiesChart
        :times="props.predictions.gender.dayPt"
        :datasets="gender"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin if-stacked-grid {
  @media (max-width: 800px) {
    @content;
  }
}

.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(1fr);

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 3fr;
  }

  @include if-stacked-grid {
    grid-template-columns: 1fr;
  }

  .tallies {
    display: flex;
    justify-content: center;
    width: 100%;

    aspect-ratio: 1/1;
    max-height: 256px;
  }

  .probabilities {
    min-height: 256px;
  }

  .title {
    font-size: 24px;
    grid-column: span 2;
    margin: 0;
    padding-top: 8px;
    text-align: center;

    @include if-stacked-grid {
      grid-column: 1;
    }

    &.gender-title {
      border-top: 1px solid var(--el-border-color);
      margin-top: 8px;
    }
  }

  .chart {
    box-sizing: border-box;
    min-width: 0;
    padding: 8px;
  }
}
</style>
