<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile } from 'element-plus'
import { ref } from 'vue'

const props = defineProps<{
  disabled?: boolean
}>()

const upload = ref<UploadInstance>()

const emit = defineEmits<{
  (e: 'selected', file: UploadRawFile): void
}>()

const handleChange: UploadProps['onChange'] = (file) => {
  emit('selected', file.raw!)
}

const handleExceed: UploadProps['onExceed'] = (files) => {
  let u = upload.value!
  u.clearFiles()
  u.handleStart(Object.assign(files[0]!, { uid: genFileId() }))
}
</script>

<template>
  <ElUpload
    ref="upload"
    accept="application/json"
    :auto-upload="false"
    :limit="1"
    :show-file-list="false"
    :on-change="handleChange"
    :on-exceed="handleExceed"
  >
    <ElButton type="primary" :disabled="props.disabled">Upload</ElButton>
  </ElUpload>
</template>
