// Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
// You may obtain a copy of the licence at:
// https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

import { RemovableRef, useStorage } from '@vueuse/core'

// Chrome has the largest read limit at 2MB, so we double that to be able to easily fit
// 2 full read buffers in the worst-case scenario.
export const DEFAULT_BUFFER_SIZE_KB = 4 * 1024

export interface Settings {
  preferByob: boolean
  bufferSizeKbOverride?: number
}

export function useSettings(): RemovableRef<Settings> {
  return useStorage('dv-settings', {
    preferByob: true,
    bufferSizeKbOverride: undefined,
  })
}
