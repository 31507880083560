<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import { ChartData, ChartOptions } from 'chart.js'
import { computed } from 'vue'
import { Line } from 'vue-chartjs'
import { Color, COLORS } from '../colors'

const kBgAlpha = '7f'

// type Color = keyof typeof flavors.latte.colors

export interface Dataset {
  label: string
  color: Color
  probabilities: Float64Array
}

const options: ChartOptions<'line'> = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      mode: 'index',
    },
    zoom: {
      limits: {
        x: { min: 'original', max: 'original' },
        y: { min: 'original', max: 'original' },
      },
      zoom: {
        wheel: { enabled: true },
        pinch: { enabled: true },
      },
      pan: { enabled: true },
    },
  },
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  scales: {
    x: {
      type: 'time',
    },
    y: {
      stacked: true,
      min: 0.0,
      max: 1.0,
    },
  },
}

const props = defineProps<{
  times: Float64Array
  datasets: Dataset[]
}>()

const data = computed<ChartData<'line'>>(() => {
  return {
    // Chart.js does support typed arrays, but the type definitions don't match.
    labels: props.times as unknown as number[],
    datasets: props.datasets.map((col, idx) => ({
      label: col.label,
      data: col.probabilities as unknown as number[],
      borderColor: COLORS[col.color],
      backgroundColor: COLORS[col.color] + kBgAlpha,
      fill: idx === 0 ? 'origin' : '-1',
    })),
  }
})
</script>

<template>
  <Line type="line" :data="data" :options="options" />
</template>
