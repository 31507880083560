<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import byteSize from 'byte-size'
import { computed } from 'vue'

const props = defineProps<{
  progress: number
  total: number
  width: number
}>()

const percentage = computed(() => Math.floor(props.progress / props.total * 100))
const label = computed(() =>
  [props.progress, props.total]
    .map(p => byteSize(p, { units: 'iec' }))
    .join(' / ')
)
</script>

<template>
  <ElProgress type="circle" :percentage="percentage" :width="props.width">
    <template #default>
      <div class="percentage">{{ percentage }}%</div>
      <div class="label">{{ label }}</div>
    </template>
  </ElProgress>
</template>

<style scoped lang="scss">
.percentage {
  font-size: 48px;
  margin: 2px;
}
.label {
  font-size: 18px;
  margin: 2px;
}

// HACK to speed up the animation
:deep(.el-progress-circle .el-progress-circle__path) {
  transition-duration: 0.1s !important;
}
</style>
