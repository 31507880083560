<!-- Copyright 2024 re:fi.64. Licensed under the EUPL-1.2 or later.
   - You may obtain a copy of the licence at:
   - https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12 -->

<script setup lang="ts">
import { Close, Setting } from '@element-plus/icons-vue'
import { ElNotification, type UploadRawFile } from 'element-plus'
import { defineAsyncComponent, ref, shallowRef } from 'vue'
import { load, PredictionsData } from './loader'
import { DEFAULT_BUFFER_SIZE_KB, useSettings } from './settings'

const DvSettingsAsync = defineAsyncComponent(() =>
  import('./components/DvSettings.vue')
)

const settingsDialogVisible = ref(false)
const settings = useSettings()

const inator = ref(false)

const loadingProgress = ref<
  {
    progress: number
    total: number
  } | undefined
>()

const predictions = shallowRef<PredictionsData>()

const clearPredictions = () => {
  predictions.value = undefined
  ElNotification({
    type: 'info',
    title: 'Cleared data',
    position: 'bottom-right',
  })
}

const processFile = async (file: UploadRawFile) => {
  loadingProgress.value = { progress: 0, total: file.size }
  predictions.value = undefined
  try {
    predictions.value = await load(
      file,
      (progress: number) => loadingProgress.value!.progress = progress,
      {
        preferByob: settings.value.preferByob,
        bufferSizeKb: settings.value.bufferSizeKbOverride
          ?? DEFAULT_BUFFER_SIZE_KB,
      },
    )
  } finally {
    loadingProgress.value = undefined
  }
}
</script>

<template>
  <ElDialog width="fit-content" v-model="settingsDialogVisible">
    <DvSettingsAsync @close="settingsDialogVisible = false" />
  </ElDialog>

  <ElHeader class="header">
    <DvHeaderTitle v-model:inator="inator" />

    <div class="spacer" />

    <ElSpace>
      <ElButton
        @click="settingsDialogVisible = true"
        :icon="Setting"
        circle
      />

      <ElButton
        @click="clearPredictions"
        :disabled="predictions === undefined"
        type="danger"
        :icon="Close"
        circle
      />

      <DvUploadButton
        @selected="processFile"
        :disabled="loadingProgress !== undefined"
      />
    </ElSpace>
  </ElHeader>

  <DvCharts
    v-if="loadingProgress === undefined && predictions !== undefined"
    :inator="inator"
    :predictions="predictions"
  />

  <div class="center">
    <ElRow
      v-if="loadingProgress === undefined && predictions === undefined"
      justify="center"
    >
      <DvExplain class="explain" />
    </ElRow>

    <DvLoadingProgress
      v-if="loadingProgress !== undefined"
      :progress="loadingProgress.progress"
      :total="loadingProgress.total"
      :width="256"
    />
  </div>

  <ElFooter class="footer">
    <div>(c) 2024 re:fi.64. Licensed under the EUPL-1.2 or later.</div>
    <a href="https://sr.ht/~refi64/discordsona">View the source @ sr.ht</a>
  </ElFooter>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);

  .spacer {
    flex-grow: 1;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.explain {
  margin: 24px;
  margin-top: 0;
  max-width: 600px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--el-text-color-regular);
  font-size: var(--el-font-size-extra-small);

  border-top: 1px solid var(--el-border-color);
}
</style>
